// src/components/NewsBox.js
import React from 'react';

const NewsBox = ({ data }) => {
    return (
        <div className="bg-white p-4 shadow rounded">
            <h2 className="text-xl font-bold mb-2">{data.title}</h2>
            <p>{data.content}</p>
            <small>{new Date(data.date).toLocaleDateString()}</small>
        </div>
    );
};

export default NewsBox;
