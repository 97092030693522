import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated, verifyToken, getToken } from '../Auth';

const ProtectedRoute = ({ children }) => {
    const [isVerified, setIsVerified] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkToken = async () => {
            const token = getToken();
            if (isAuthenticated() && token) {
                const isValid = await verifyToken(token);
                setIsVerified(isValid);
            }
            setLoading(false);
        };

        checkToken();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isVerified) {
        return <Navigate to="/login" />;
    }

    return React.cloneElement(children, { isVerified });
};

export default ProtectedRoute;
