// src/components/Header.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');
    const { i18n } = useTranslation();

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        localStorage.setItem('darkMode', !darkMode);
        document.documentElement.classList.toggle('dark', !darkMode);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const handleLogout = (event) => {
        event.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/Auth/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(response => response.json())
            .then(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('apiInfo');
                window.location.href = '/';
            })
            .catch(error => console.error('Error logging out:', error));
    };


    return (
        <header className="p-4 bg-gray-800 text-white flex justify-between items-center">
            <div className="logo">
                <a href="/" className="text-xl font-bold">EkonomiAPI</a>
            </div>
            <nav className="flex space-x-4">
                <button onClick={handleLogout} className="hover:underline">
                    <i className="fas fa-portal-exit"></i>
                </button>
                <button onClick={() => changeLanguage('sv')} className="hover:underline">SV</button>
                <button onClick={() => changeLanguage('en')} className="hover:underline">EN</button>
                <button onClick={toggleDarkMode} className="bg-gray-600 px-2 py-1 rounded">
                    {darkMode ? 'Light Mode' : 'Dark Mode'}
                </button>
            </nav>
        </header>
    );
};

export default Header;
