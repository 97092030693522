// src/components/UsageBox.js
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const UsageBox = ({ data }) => {
    const usageData = Object.keys(data).map((day) => ({
        name: day,
        timeSaved: data[day],
    }));

    return (
        <div className="bg-white p-4 shadow rounded">
            <h2 className="text-xl font-bold mb-2">Usage history</h2>

        </div>
    );
};

export default UsageBox;
