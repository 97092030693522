import React, { useEffect, useState } from 'react';
import {Link, NavLink} from 'react-router-dom';
import { apiRequest } from '../utils/Api';
import md5 from 'md5'; // Importing MD5 hashing function

const Sidebar = () => {
    const [toolsByCategory, setToolsByCategory] = useState({});
    const [collapsedCategories, setCollapsedCategories] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Load the collapsed state and preloaded menu from localStorage on mount
    useEffect(() => {
        const savedCollapsedState = localStorage.getItem('collapsedCategories');
        if (savedCollapsedState) {
            setCollapsedCategories(JSON.parse(savedCollapsedState)); // Parse and load saved collapsed state
        }

        const savedMenu = localStorage.getItem('toolsByCategory');
        const savedMenuHash = localStorage.getItem('menuHash');

        // Preload menu if found in localStorage
        if (savedMenu && savedMenuHash) {
            setToolsByCategory(JSON.parse(savedMenu));
            setLoading(false); // Assume no need to fetch immediately
        }
    }, []);

    useEffect(() => {
        const fetchToolsList = async () => {
            try {
                const data = await apiRequest('/tools/toolsList', 'POST');

                if (data.error) {
                    setError(data.error);
                } else {
                    const newMenuHash = md5(JSON.stringify(data.tools));

                    // Compare the new hash with the one in localStorage
                    const savedMenuHash = localStorage.getItem('menuHash');
                    if (savedMenuHash !== newMenuHash) {
                        // If the hash is different, update the menu and the hash in localStorage
                        localStorage.setItem('toolsByCategory', JSON.stringify(data.tools));
                        localStorage.setItem('menuHash', newMenuHash);
                        setToolsByCategory(data.tools);  // Update the state with fresh data
                    }

                    // If no collapsed state saved, initialize all categories as expanded
                    if (!localStorage.getItem('collapsedCategories')) {
                        const initialCollapsedState = Object.keys(data.tools).reduce((acc, category) => {
                            acc[category] = false; // Default state: expanded
                            return acc;
                        }, {});
                        setCollapsedCategories(initialCollapsedState);
                    }
                }

                setLoading(false);
            } catch (err) {
                setError('Failed to load tools list');
                setLoading(false);
            }
        };

        // Always fetch data to ensure the latest hash comparison, but skip if menu is already preloaded
        if (Object.keys(toolsByCategory).length === 0) {
            fetchToolsList();
        }
    }, [toolsByCategory]);

    // Function to toggle the visibility of a category's tools
    const toggleCategory = (category) => {
        const updatedState = {
            ...collapsedCategories,
            [category]: !collapsedCategories[category], // Toggle state
        };
        setCollapsedCategories(updatedState);
        localStorage.setItem('collapsedCategories', JSON.stringify(updatedState)); // Save state to localStorage
    };

    if (loading) {
        return <div className="p-4">Loading...</div>;
    }

    if (error) {
        return <div className="p-4 text-red-500">{error}</div>;
    }

    return (
        <aside className="w-64 bg-gray-800 text-white flex-shrink-0 overflow-y-auto">
            <div className="p-4">
                <h2 className="text-2xl font-bold"><Link to="/dashboard">Dashboard</Link></h2>
                <nav className="mt-4">
                    <ul>
                        {Object.keys(toolsByCategory).map((category) => (
                            <li key={category}>
                                <div
                                    className="font-bold mt-4 cursor-pointer flex justify-between items-center"
                                    onClick={() => toggleCategory(category)}
                                >
                                    {category}
                                    {/* Add indicator for collapse/expand */}
                                    <span>{collapsedCategories[category] ? '▼' : '▶'}</span>
                                </div>
                                {!collapsedCategories[category] && (
                                    <ul className="ml-4 mt-2">
                                        {toolsByCategory[category].map((tool) => (
                                            tool.url && tool.id !== 0 ? (
                                                <li key={tool.id}>
                                                    <NavLink
                                                        to={tool.url}
                                                        className={({ isActive }) =>
                                                            `block p-2 rounded flex items-center space-x-2 hover:bg-gray-700 ${isActive ? 'bg-gray-700' : ''}`
                                                        }
                                                    >
                                                        {/* Active icon (dot or checkmark) */}
                                                        {({ isActive }) => (
                                                            <span className="flex-shrink-0">
                                                                {isActive ? (
                                                                    <svg className="w-4 h-4 text-green-500" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path>
                                                                    </svg>
                                                                ) : (
                                                                    <span className="w-4 h-4"></span>
                                                                )}
                                                            </span>
                                                        )}
                                                        <span>{tool.name}</span>
                                                    </NavLink>
                                                </li>
                                            ) : null
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default Sidebar;
