// src/components/Footer.js
import React from 'react';

const Footer = () => {
    return (
        <footer className="p-4 bg-gray-800 text-white text-center">
            <p>&copy; 2024 EkonomiAPI. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
