// src/pages/Dashboard.js
import React, { useEffect, useState } from 'react';
import NewsBox from '../components/NewsBox';
import UsageBox from '../components/UsageBox';
import SavingsBox from '../components/SavingsBox';
import CurrencyRatesBox from '../components/CurrencyRatesBox';
import { apiRequest } from '../utils/Api';

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const data = await apiRequest('/dashboard/full', 'POST'); // Fetch data from your API

                // Error from the API
                if (data.error) {
                    setError(data.error); // Set the error message from API response
                } else if (!data) {
                    setError('No data available'); // If data is undefined
                } else {
                    setDashboardData(data); // Successfully fetched data
                }

                setLoading(false);
            } catch (err) {
                setError('Failed to load dashboard data'); // Catch any fetch or network errors
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Loading indicator while waiting for the response
    }

    if (error) {
        return <div className="text-red-500 font-bold">{error}</div>; // Display error message
    }

    return (
        <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Site News - 50% width */}
            <div className="md:col-span-1">
                <NewsBox data={dashboardData.news} />
            </div>
            {/* Usage and Currency Rates - 25% width each */}
            <div className="md:col-span-1 grid grid-cols-1 md:grid-cols-2 gap-4">
                <SavingsBox data={dashboardData.savings} />
                <UsageBox data={dashboardData.usage} />
                <CurrencyRatesBox data={dashboardData.currencies} />
            </div>
            {/* Space for more boxes below */}
        </div>
    );
};

export default Dashboard;
