import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import LoggedOutHeader from './components/LoggedOutHeader'; // New header for logged out users
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import Login from './pages/Login';
import Sales from './pages/Sales';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Bokio from './pages/tools/Bokio';
import CollectorCard from "./pages/tools/CollectorCard";
import ToolFooter from './components/ToolFooter';
import { isAuthenticated } from './Auth'; // Auth helper function to check if the user is logged in
import './styles/FontAwesome.css';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // Check if the user is logged in on mount
        const checkAuthStatus = () => {
            const loggedIn = isAuthenticated(); // Check if token exists or valid
            setIsLoggedIn(loggedIn);
        };

        checkAuthStatus();
    }, []);

    return (
        <Router>
            <div className="flex">
                {/* Sidebar */}
                {isLoggedIn && <Sidebar />} {/* Show sidebar only if logged in */}

                <div className="flex flex-col w-full">
                    {/* Conditional Header */}
                    {isLoggedIn ? <Header /> : <LoggedOutHeader />} {/* Show appropriate header */}

                    {/* Main Content */}
                    <main className="flex-grow p-4">
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/sales" element={<Sales />} />
                            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                            <Route path="/tool/bokio" element={<ProtectedRoute><Bokio /></ProtectedRoute>} />
                            <Route path="/tool/collectorcard" element={<ProtectedRoute><CollectorCard /></ProtectedRoute>} />
                            <Route path="*" element={<Navigate to="/sales" />} />
                        </Routes>
                    </main>

                    {/* Footer */}
                    <Footer />

                    {/* Tool Footer */}
                    <ToolFooterWrapper />
                </div>
            </div>
        </Router>
    );
}

function ToolFooterWrapper() {
    const location = useLocation();

    // Check if the current route matches /tool/*
    const isToolPage = location.pathname.startsWith('/tool/');

    return isToolPage ? <ToolFooter /> : null;
}

export default App;
