import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ToolScriptInstaller = ({ toolName, userScriptUrl }) => {
    const [isFirefox, setIsFirefox] = useState(false);
    const [isChrome, setIsChrome] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();  // Importing useTranslation hook from i18next

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        setIsFirefox(userAgent.indexOf('firefox') > -1);
        setIsChrome(userAgent.indexOf('chrome') > -1);
    }, []);

    const handleScriptInstall = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    return (
        <div className="max-w-2xl mx-auto mt-6 p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-2xl font-bold mb-4">{toolName} {t('Tool')}</h1>
            <p>
                {t('UserScriptInstructions')}.
            </p>
            {/* Show different instructions based on the browser */}
            <div className="mt-6">
                {isFirefox ? (
                    <button
                        className="w-full py-2 px-4 bg-green-500 text-white rounded-md"
                        onClick={handleScriptInstall}
                    >
                        {t('Install')} {toolName} {t('Tool')} (Greasemonkey)
                    </button>
                ) : isChrome ? (
                    <button
                        className="w-full py-2 px-4 bg-blue-500 text-white rounded-md"
                        onClick={handleScriptInstall}
                    >
                        {t('Install')} {toolName} {t('Tool')} (Tampermonkey)
                    </button>
                ) : (
                    <div className="text-red-500">
                        {t('BrowserNotSupported')}
                    </div>
                )}
            </div>

            {/* Modal to confirm installation */}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg p-6">
                        <div className="flex justify-between items-center">
                            <h5 className="text-xl font-bold">{toolName} {t('UserScriptInstallation')}</h5>
                            <button
                                className="text-gray-400 hover:text-gray-600"
                                onClick={closeModal}
                            >
                                ✕
                            </button>
                        </div>
                        <div className="mt-4">
                            <p>
                                {t('HaveYouInstalled')} {' '}
                                {isFirefox ? (
                                    <a
                                        href="https://addons.mozilla.org/en-US/firefox/addon/greasemonkey/"
                                        className="text-blue-500 underline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Greasemonkey
                                    </a>
                                ) : (
                                    <a
                                        href="https://www.tampermonkey.net/"
                                        className="text-blue-500 underline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Tampermonkey
                                    </a>
                                )}
                                ?<br />
                                {t('ExtraFunctionalityNeeded')}
                            </p>
                        </div>
                        <div className="mt-4 flex justify-end">
                            <button
                                className="py-2 px-4 bg-red-500 text-white rounded-md mr-2"
                                onClick={closeModal}
                            >
                                {t('NoClose')}
                            </button>
                            <a
                                href={userScriptUrl}
                                className="py-2 px-4 bg-green-500 text-white rounded-md"
                            >
                                {t('YesInstall')}
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ToolScriptInstaller;
