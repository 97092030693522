import React, {useState, useEffect} from 'react';
import {apiRequest} from '../../utils/Api';
import {useTranslation} from 'react-i18next';
import ToolFooter from '../../components/ToolFooter';

const CollectorCard = () => {
    const [file, setFile] = useState(null);
    const [onlyCard, setOnlyCard] = useState(true);  // Default to 'Only Card' purchases
    const [convertedFile, setConvertedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isApiDisabled, setIsApiDisabled] = useState(true);
    const {t} = useTranslation();

    useEffect(() => {
        // Fetch the isApiDisabled value from localStorage after login
        const apiInfo = localStorage.getItem('apiInfo');
        setIsApiDisabled(apiInfo === 'false');
    }, []);

    // API Documentation for the Collector Kort tool
    const apiDocumentation = {
        description: 'Convert a Collector Kort file via the API.',
        calls: {
            '/tools/collectorcard': 'POST'
        },
        post: {
            file: 'base64-encoded file -- The file to convert',
            onlyCard: 'boolean -- Only process card transactions'
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleCheckboxChange = () => {
        setOnlyCard(!onlyCard);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        if (!file) {
            setError(t('PleaseSelectFile'));
            setLoading(false);
            return;
        }

        const reader = new FileReader();
        reader.onloadend = async () => {
            const base64File = reader.result;

            try {
                const response = await apiRequest('/tools/collectorkort', 'POST', {
                    file: base64File,
                    onlyCard: onlyCard
                });

                if (response.error) {
                    setError(response.error);
                } else {
                    setConvertedFile(response.convertedFile);
                }
            } catch (err) {
                setError(t('FailedToProcessFile'));
            }

            setLoading(false);
        };

        reader.readAsDataURL(file);
    };

    const handleDownload = () => {
        const a = document.createElement('a');
        a.href = `data:text/csv;base64,${convertedFile}`;
        a.download = 'converted_collectorkort.csv';
        a.click();
    };

    return (
        <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-2xl font-bold mb-4">{t('CollectorKortTool')}</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <ol className="list-decimal">
                    <li className="mb-3">
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                id="onlyCard"
                                checked={onlyCard}
                                onChange={handleCheckboxChange}
                                className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                            <label htmlFor="onlyCard" className="ml-2 block text-sm text-gray-900">
                                {t('OnlyCardTransactions')}
                            </label>
                        </div>
                    </li>
                    <li>
                        <input
                            type="file"
                            id="fileToUpload"
                            onChange={handleFileChange}
                            className="text-sm text-stone-500
   file:mr-5 file:py-1 file:px-3 file:border-[1px]
   file:text-xs file:font-medium
   file:bg-stone-50 file:text-stone-700
   hover:file:cursor-pointer hover:file:bg-blue-50
   hover:file:text-blue-700mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </li>
                </ol>
                <button type="submit"
                        className={`w-full py-2 px-4 bg-indigo-600 text-white rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}>
                    {loading ? t('Processing') : t('ConvertFile')}
                </button>
            </form>

            {error && <div className="mt-4 text-red-500 text-sm">{error}</div>}

            {convertedFile && (
                <div className="mt-4">
                    <button className="w-full py-2 px-4 bg-green-500 text-white rounded-md" onClick={handleDownload}>
                        {t('DownloadConvertedFile')}
                    </button>
                </div>
            )}

            {/* Conditional ToolFooter showing API documentation */}
            <ToolFooter apiDocumentation={apiDocumentation} isApiDisabled={isApiDisabled}/>
        </div>
    );
};

export default CollectorCard;
