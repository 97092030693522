// src/components/ToolFooter.js
import React from 'react';
import { NavLink } from 'react-router-dom';

const ToolFooter = ({ apiDocumentation, isApiDisabled }) => {
    if (isApiDisabled) {
        return null; // Do not render footer if API info is disabled
    }

    return (
        <footer className="py-4 mt-8">
            <div className="container mx-auto">
                <p className="text-sm">
                    Please note all API documentation is for advanced users only. The documentation is only available in
                    English. To turn off the API documentation message please visit the{' '}
                    <NavLink to="/control/user" className="text-blue-500 underline">
                        Control panel
                    </NavLink>.<br/>
                    This tool is also available via our API. To gain access, visit{' '}
                    <NavLink to="/info/api-keys" className="text-blue-500 underline">
                        API Keys Info
                    </NavLink>{' '}
                    to retrieve your own API key and read up on Authentication.
                </p>
                {apiDocumentation && (
                    <div>
                        <h2 className="text-lg font-bold mt-4">API Documentation</h2>

                        {/* Display API description */}
                        <p>{apiDocumentation.description}</p>
                        {/* Display API calls */}
                        <h3 className="text-md font-semibold mt-2">API Calls:</h3>
                        <ul>
                            {Object.keys(apiDocumentation.calls).map((call) => (
                                <li key={call}>
                                    <strong>{process.env.REACT_APP_API_URL}{call}</strong> - {apiDocumentation.calls[call]}
                                </li>
                            ))}
                        </ul>

                        {/* Display POST request fields */}
                        {apiDocumentation.post && (
                            <>
                                <h3 className="text-md font-semibold mt-2">POST Request Fields:</h3>
                                <ul>
                                    {Object.keys(apiDocumentation.post).map((field) => (
                                        <li key={field}>
                                            <strong>{field}</strong> - {apiDocumentation.post[field]}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                )}
            </div>
        </footer>
    );
};

export default ToolFooter;
