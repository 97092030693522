// src/components/CurrencyRatesBox.js
import React from 'react';

const CurrencyRatesBox = ({ data }) => {
    return (
        <div className="bg-white p-4 shadow rounded">
            <h2 className="text-xl font-bold mb-2">Currency Rates (SEK)</h2>
            <ul>
                {Object.entries(data).map(([currency, rate]) => (
                    <li key={currency}>
                        {currency}: {rate}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CurrencyRatesBox;
