// src/components/UsageBox.js
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const SavingsBox = ({ data }) => {
    const usageData = Object.keys(data).map((day) => ({
        name: day,
        timeSaved: data[day],
    }));

    return (
        <div className="bg-white p-4 shadow rounded">
            <h2 className="text-xl font-bold mb-2">Time Saved Per Day</h2>
            <ResponsiveContainer width="100%" height={200}>
                <BarChart data={usageData}>
                    <XAxis dataKey="name" />
                    <YAxis label={{ value: 'Hours', angle: -90, position: 'insideLeft' }} />
                    <Tooltip />
                    <Bar dataKey="timeSaved" fill="#4A90E2" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default SavingsBox;
