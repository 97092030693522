// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            Bank: 'Bank',
            SIE: 'SIE',
            PDF: 'PDF',
            Mejl: 'Mail',
            Övrigt: 'Other',
            Administration: 'Administration',
            Hours: 'Hours',
            Mon: 'Mon',
            Tue: 'Tue',
            Wed: 'Wed',
            Thu: 'Thu',
            Fri: 'Fri',
            Sat: 'Sat',
            Sun: 'Sun',
            "Site News": "EkonomiAPI News",
            "Time Saved Per Day": "Time Saved Per Day",
            "Currency Conversion Rates": "Currency Conversion Rates",
            "to": "to",
            "Tool": "Tool",
            "Install": "Install",
            "BokioTool": "Bokio",
            "UploadCSVFile": "Upload CSV File",
            "ConvertFile": "Convert File",
            "Processing": "Processing...",
            "PleaseSelectFile": "Please select a file to upload.",
            "FailedToProcessFile": "Failed to process the file.",
            "DownloadConvertedFile": "Download Converted File",
            "UserScriptInstructions": "This tool requires installing an additional tool to work properly.",
            "BokioToolDescription": "Converts Bokio exports to a simple format as used in Fortnox as an example",
            "CollectorKortTool": "Collector card",
            "UploadFile": "Upload file",
            "OnlyCardTransactions": "Only process card transactions",
            welcomeHeader: 'Welcome to EkonomiAPI',
            pitchHeader: 'Time is Money – Invest in Efficiency',
            pitchPara1: 'In today’s fast-paced world, time is one of the most valuable resources you have. Managing your financial tasks manually or using outdated processes can take hours out of your day – time that could be spent growing your business or focusing on what really matters. That’s where our tools come in.',
            pitchPara2: 'By automating tedious financial processes, we not only streamline your workflow but also help you reclaim valuable time. Whether you’re handling bank transactions, accounting, or invoicing, our tools are designed to perform these tasks quickly and efficiently – so you don’t have to do them manually.',
            pitchSubHeader1: 'How Time Savings Translate to Money Savings:',
            pitchList1: 'Less Time on Administrative Tasks',
            pitchList1Description: 'Every hour you or your team spends on repetitive manual tasks is an hour not spent on revenue-generating activities. With automation, tasks that used to take hours can now be completed in minutes.',
            pitchList2: 'Avoid Costly Mistakes',
            pitchList2Description: 'Human errors in financial management can lead to costly mistakes. By using our tools to automate calculations and data entry, you reduce the risk of errors, protecting your bottom line.',
            pitchList3: 'Increased Productivity',
            pitchList3Description: 'Imagine what you could accomplish with the extra hours you save each week. You could focus on strategic planning, customer relationships, or growing your business, directly impacting your revenue.',
            pitchList4: 'Reduce Labor Costs',
            pitchList4Description: 'Instead of hiring extra staff to manage differences in financial records, our tools take care of these tasks automatically, reducing your staffing costs.',
            pitchSubHeader2: 'You can integrate our tools into your existing workflow, saving you time and money.',
            pitchPara3: 'Our tools are also available via our API, allowing you to integrate them into your existing workflow.',
            pitchSubHeader3: 'Start saving time, money, and resources today',
            pitchPara4: 'Using our wast library of tools, you can automate your financial processes and save time, money, and resources.',
            registerButton: 'Sign Up Now'
        },
    },
    sv: {
        translation: {
            Bank: 'Bank',
            SIE: 'SIE',
            PDF: 'PDF',
            Mejl: 'Mejl',
            Övrigt: 'Övrigt',
            Administration: 'Administration',
            Hours: 'Timmar',
            Mon: 'Mån',
            Tue: 'Tis',
            Wed: 'Ons',
            Thu: 'Tor',
            Fri: 'Fre',
            Sat: 'Lör',
            Sun: 'Sön',
            "Site News": "EkonomiAPI nytt",
            "Time Saved Per Day": "Tidsbesparning per dag",
            "Currency Conversion Rates": "Valutakurser",
            "Install": "Installera",
            "to": "till",
            "Tool": "Verktyg",
            "BokioTool": "Bokio",
            "UploadCSVFile": "Ladda upp CSV-fil",
            "ConvertFile": "Konvertera fil",
            "Processing": "Bearbetar...",
            "PleaseSelectFile": "Vänligen välj en fil att ladda upp.",
            "FailedToProcessFile": "Det gick inte att bearbeta filen.",
            "DownloadConvertedFile": "Ladda ner konverterad fil",
            "UserScriptInstructions": "Detta verktyg behöver installera ett extra verktyg för att fungera korrekt.",
            "BokioToolDescription": "Konverterar Bokio-exporter till ett enkelt format som används i Fortnox t.ex.",
            "CollectorKortTool": "Collector kort",
            "UploadFile": "Ladda upp fil",
            "OnlyCardTransactions": "Bearbeta endast korttransaktioner",
            welcomeHeader: 'Välkommen till EkonomiAPI',
            pitchHeader: 'Tid är pengar – Investera i effektivitet',
            pitchPara1: 'I dagens snabba värld är tid en av de mest värdefulla resurserna du har. Att hantera dina ekonomiska uppgifter manuellt eller med föråldrade processer kan ta timmar av din dag – tid som skulle kunna användas till att utveckla ditt företag eller fokusera på vad som verkligen är viktigt. Det är här våra verktyg kommer in i bilden.',
            pitchPara2: 'Genom att automatisera tråkiga ekonomiska processer effektiviserar vi inte bara ditt arbetsflöde, utan hjälper dig också att återfå värdefull tid. Oavsett om du hanterar banktransaktioner, bokföring eller fakturering, är våra verktyg utformade för att sköta dessa uppgifter snabbt och effektivt – så att du slipper göra det manuellt.',
            pitchSubHeader1: 'Hur tidsbesparingar blir ekonomiska besparingar:',
            pitchList1: 'Mindre tid på administrativa uppgifter',
            pitchList1Description: 'Varje timme du eller ditt team spenderar på repetitiva manuella uppgifter är en timme som inte läggs på inkomstbringande aktiviteter. Med automatisering kan uppgifter som tidigare tog timmar nu utföras på minuter.',
            pitchList2: 'Undvik kostsamma misstag',
            pitchList2Description: 'Mänskliga fel i ekonomihanteringen kan leda till kostsamma misstag. Genom att använda våra verktyg för att automatisera beräkningar och datainmatning minskar du risken för fel, vilket skyddar ditt resultat.',
            pitchList3: 'Ökad produktivitet',
            pitchList3Description: 'Föreställ dig vad du skulle kunna åstadkomma med de extra timmar du sparar varje vecka. Du kan fokusera på strategisk planering, kundrelationer eller att utveckla ditt företag, vilket direkt kan påverka dina intäkter.',
            pitchList4: 'Minska arbetskostnader',
            pitchList4Description: 'Istället för att anställa extra personal för att hantera bokföring eller ekonomiska register, tar våra verktyg hand om dessa uppgifter automatiskt, vilket minskar dina personalkostnader.',
            pitchSubHeader2: 'Du kan integrera våra verktyg i ditt befintliga arbetsflöde, vilket sparar tid och pengar.',
            pitchPara3: 'Våra verktyg är också tillgängliga via vår API, vilket gör att du kan integrera dem i ditt befintliga arbetsflöde.',
            pitchSubHeader3: 'Spara tid, pengar och resurser redan idag.',
            pitchPara4: 'Med vår stora verktygslåda kan du automatisera dina ekonomiska processer och spara tid, pengar och resurser.',
            registerButton: 'Registrera dig nu'
        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'sv', // Default language
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
