// src/pages/Sales.js
import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const Sales = () => {
    const {t} = useTranslation();
    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
            <div className="container mx-auto p-6 bg-white shadow-md rounded-lg">
                <h1 className="text-4xl mb-4 text-center">{t('welcomeHeader')}</h1>
                <h2 className="text-3xl font-bold mb-4 text-center">{t('pitchHeader')}</h2>
                <p className="mb-4 text-lg">{t('pitchPara1')}</p>
                <p className="mb-4 text-lg">{t('pitchPara2')}</p>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="md:col-span-2">


                        <h2 className="text-2xl font-bold mb-2">{t('pitchSubHeader1')}</h2>
                        <ul className="list-disc list-inside mb-4 text-lg">
                            <li><strong>{t('pitchList1')}:</strong> {t('pitchList1Description')}</li>
                            <li><strong>{t('pitchList2')}:</strong> {t('pitchList2Description')}</li>
                            <li><strong>{t('pitchList3')}:</strong> {t('pitchList3Description')}</li>
                            <li><strong>{t('pitchList4')}:</strong> {t('pitchList4Description')}</li>
                        </ul>

                        <h2 className="text-2xl font-bold mb-2">{t('pitchSubHeader2')}</h2>
                        <p className="text-lg mb-4">{t('pitchPara3')}</p>

                        <h2 className="text-2xl font-bold mb-2">{t('pitchSubHeader3')}</h2>
                        <p className="text-lg">{t('pitchPara4')}</p>
                    </div>

                    <div className="flex flex-col justify-center items-center">
                        <img src="/images/timeismoney.webp" alt="EkonomiAPI" className="w-full h-auto"/>
                    </div>
                </div>



                <Link to="/register" className="block p-2 bg-blue-500 text-white rounded text-center mt-6 w-44 mx-auto">
                    {t('registerButton')}
                </Link>
            </div>
        </div>
    );
};

export default Sales;
