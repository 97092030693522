// src/components/LoggedOutHeader.js
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const LoggedOutHeader = () => {
    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');
    const { i18n } = useTranslation();
    const navigate = useNavigate();


    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        localStorage.setItem('darkMode', !darkMode);
        document.documentElement.classList.toggle('dark', !darkMode);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <header className="p-4 bg-gray-800 text-white flex justify-between items-center">
            <div className="logo">
                <a href="/" className="text-xl font-bold">EkonomiAPI</a>
            </div>
            <nav className="flex space-x-4">
                <button onClick={() => navigate('/login')} className="hover:underline">Login</button>
                <button onClick={() => changeLanguage('sv')} className="hover:underline">SV</button>
                <button onClick={() => changeLanguage('en')} className="hover:underline">EN</button>
                <button onClick={toggleDarkMode} className="bg-gray-600 px-2 py-1 rounded">
                    {darkMode ? 'Light Mode' : 'Dark Mode'}
                </button>
            </nav>
        </header>
    );
};

export default LoggedOutHeader;
